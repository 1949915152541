export const actData = [
	{
		name: 'Road Tech Marine',
		address: 'Cnr Gladstone & Albany Street FyshwickACT 2609',
		phone: '02 6239 2155'
	},
	{
		name: 'Pride Caravan RV Marine',
		address: '9 Kembla Street, Fyshwick ACT 2609',
		phone: '02 62 800655'
	},
]