export const ntData = [
	{
		name: 'Road Tech Marine',
		address: '474 Stuart  Hwy Winnellie 0820',
		phone: '08 8947 1504'
	},
	{
		name: 'Jayco Darwin',
		address: '790 Stuart Hwy Berrimah 0828',
		phone: '08 8984 4933'
	},
]