const data = [
	'One Product Does All!',
	'Disinfects',
	'Deodourises',
	'Cleans',
	'Safe to Discharge into Septic, Bio and Compost Systems',
	'Easy to use',
	'Water based Perfume as does not linger',
	'Our 2 gram Mini Oxygen Tab has NO perfume',
	'Non Toxic',
	'Breaks down to Oxygen & Water',
	'No Harmful chemicals',
	'No Damage to seals/bowl',
	'Can be used with saltwater',
	'Biodegradable',
	'Enviro-friendly',
	'Compact/lightweight',
	'Replaces All other products',
	'Can be used in other areas',
	'Very Cost Effective',
	'TGA Option C Tested',
	'Australian Made',
];

export default data;