export const vicData = [
	{
		name: 'Award RV Superstore',
		address: '924 Burwood Hwy Ferntree Gully VIC 3156',
		phone: '03 9753 5511'
	},
	{
		name: 'Time RV Centre',
		address: '25 –27 Ainslie Road Campbellfield 3061',
		phone: 'Tel 03 9333 0699'
	},
	{
		name: 'Caravans West',
		address: '90 A Davies Avenue Sunshine North 3020',
		phone: '0419 402603'
	},
	{
		name: 'MOE CARAVAN SERVICE & REPAIR CENTRE',
		address: '68 Waterloo Road Moe 3825',
		phone: '03 5127 1947'
	},
	{
		name: 'Malandy Outdoors Adventure',
		address: 'Unit 11 / 7  Dunstans  Crt Thomastown 3074',
		phone: '03 9017 7961'
	},
	{
		name: 'Bayswater Jayco',
		address: '140 Canterbury Road Bayswater 3153',
		phone: '9761 5022'
	},
	{
		name: 'Everything Caravans',
		address: '174 Boundary Road Braeside 3195',
		phone: '9574 1900'
	},
	{
		name: 'Carac’s Dandenong Caravan Accessories',
		address: '2 Zenith Road Dandenong 3175',
		phone: '9794 7977'
	},
	{
		name: 'Dandenong Jayco',
		address: '256A Frankston Dandenong Road Dandenong 3175',
		phone: '9791 6599'
	},
	{
		name: 'South East Caravan Centre',
		address: '700 Dandenong-Frankston Road Carrum Downs 3201',
		phone: '1300 863117'
	},
	{
		name: 'CARAVAN GEAR',
		address: '217 Learmonth Rd, Wendouree VIC 3355',
		phone: '03 9998 4699'
	},
	{
		name: 'Page Bros  Caravans  Jayco',
		address: '648 South Road Moorabbin 3189',
		phone: '9786 1000'
	},
	{
		name: 'Lyal Eales Stores',
		address: '178-180 High Street Maryborough',
		phone: ' 03 5461 1911'
	},
	{
		name: 'Lyal Eales Stores',
		address: '29 Maryborough–Dunolly Road Maryborough 3465',
		phone: '03 5461 4222'
	},
	{
		name: 'Greg',
		address: '6 Elma Crt Daisy Hill 3465',
		phone: '03 5461 3900'
	},
	{
		name: 'Prestige Jayco',
		address: '194 Bellarine Hwy Newcombe 3291',
		phone: '03 5240 0099'
	},
	{
		name: 'Lyal Eales Stores',
		address: '179–181 Barker Street Castlemaine 3450',
		phone: '03 5472 4422'
	},
	{
		name: 'Lyal Eales Stores',
		address: '145 –147 Barkly Street Ararat 3377',
		phone: '03 5352 3905'
	},
	{
		name: 'Lyal Eales Stores',
		address: '113 Main Street Stawell 3380',
		phone: '03 9794 7977'
	},
	{
		name: 'Jayco Horsham',
		address: '107 Stawell Road Horsham 3400',
		phone: '03 5382 4100'
	},
	{
		name: 'Portland Disposals Camping & Hardware',
		address: '13 Julia Street Portland 3305',
		phone: '03 5523 1441'
	},
	{
		name: 'The Great Outdoor Centre',
		address: '217–231 Learmonth RoadWendouree3355',
		phone: '03 5174 1381'
	},
	{
		name: 'Kyabram 4 WD & Camping',
		address: '255 Allan Street Kyabram 3620',
		phone: '03 5852 1822'
	},
	{
		name: 'Lyal Eales Stores',
		address: '59-61 Napier  Street StArnaud3 478',
		phone: '03 5495 2021'
	},
	{
		name: 'Echuca Disposals',
		address: '598 High Street Echuca 3584',
		phone: '03 5482 3041'
	},
	{
		name: 'Echuca Caravan & Outdoors',
		address: '600 High Street Echuca 3584',
		phone: '03 5480 3023'
	},
	{
		name: 'Outback Supplies Echuca',
		address: '1 / 104 Northern Hwy Echuca 3564',
		phone: '03 5480 0855'
	},
	{
		name: 'Halls  Caravans - Jayco',
		address: '693–695 15thStreet Mildura 3500',
		phone: '03 5021 3235'
	},
	{
		name: 'Wirr-Away Motor Homes',
		address: '6 B Hynes Court Mildura 3500',
		phone: '03  5023 0230'
	},
	{
		name: 'Highway  Caravan  Centre',
		address: '1975  Princes Hwy Nar Nar Goon 3812',
		phone: '03 5942 5895'
	},
	{
		name: 'Jayco Gippsland',
		address: '718 Princes Hwy Bairnsdale 3875',
		phone: '03 5152 2510'
	},
	{
		name: 'New Age Caravans Gippsland',
		address: '603 Main Street Bairnsdale 3875',
		phone: '03 5152 6776'
	},
	{
		name: 'Ian  Grants  Caravans',
		address: '5357 Princes Hwy Traralgon 3844',
		phone: '03 5174 1381'
	},
	{
		name: 'Bright  Disposals  & Outdoor  Centre',
		address: '9  Ireland Street Bright 3741',
		phone: '03  5755 1818'
	},
	{
		name: 'MT Beauty Hardware & Drapery',
		address: '12 –14 Hollands Road, MT Beauty 3699',
		phone: '03 5754 1999'
	},
]